.export-body {
  padding: 20px 30px;
  .MuiFormControl-marginNormal {
    padding: 10px 15px !important;
    border: 3px solid #e5e5e5 !important;
    border-radius: 15px !important;
    >label {
      margin-left: 15px;
    }
  }
}