@import '../../../../assets/styles/scss/style.scss';

.header-tabs {
  .appBar {
    .tabs {
      justify-content: space-between;
    }
  }
}
.tab {
  min-height: 80vh;
}
