@font-face {
  font-family: "Carmen Sans Regular";
  src: url("/assets/styles/fonts/Carmen-Sans-Regular.otf");
}

@font-face {
  font-family: "Carmen Sans";
  src: url("/assets/styles/fonts/Carmen-Sans-Medium.otf");
}

@font-face {
  font-family: "Carmen Sans Bold";
  src: url("/assets/styles/fonts/Carmen-Sans-Bold.otf");
}

@font-face {
  font-family: "Roboto Regular";
  src: url("/assets/styles/fonts/Roboto-Regular.woff");
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/styles/fonts/Roboto-Medium.woff");
}

@font-face {
  font-family: "Roboto Bold";
  src: url("/assets/styles/fonts/Roboto-Bold.woff");
}

@import "common";
@import "grid";
@import "layout";
@import "text";