@import './_variables.scss';

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Carmen Sans";
  margin-bottom: 0;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

.text-dark-grey {
  color: $DarkGrey !important;
}

.text-grey {
  color: $MainGrey !important;
}

.text-red {
  color: $Red !important;
}

.text-pink {
  color: $MainPink;
}

.text-white {
  color: $White;
}

.text-green {
  color: $MainGreen !important;
}

.font-carmen {
  font-family: "Carmen Sans" !important;
}

.font-roboto {
  font-family: "Roboto" !important;
  text-transform: none !important;
}

.text-transform-normal {
  text-transform: none;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.text-resend-code {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: $MainPink;
  margin: 10px auto;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: $MainGrey;
  }
}

.text-describe-code {
  font-family: "Roboto Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  height: 60px;
  line-height: 18px;
  color: $DarkGrey;
  margin: 10px 0px;
}

.error-message {
  color: $Red;
  margin: 30px 20px
}

::placeholder {
  opacity: 1 !important;
}

.fs-12 {
  font-size: 12px;
}

.fs-18 {
  font-size: 18px;
}

.lh-18 {
  line-height: 18px;
}
