@import '../../../../assets/styles/scss/style.scss';
.custom-menu-item {
  margin: 10px !important;
  .img-plus {
    margin-right: 20px;
  }
  .name-modal {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: $MainGrey;
  }
}