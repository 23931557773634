@import "../../../../assets/styles/scss/style.scss";

.action-modal {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
    h3 {
        text-align: center;
    }
}
