.skeleton-registration {
  width: 100%;
  height: 100%;
  .title-registration {
    height: 50px;
  }
  .title-step {
    height: 80px;
  }
  .text {
    height: 200px;
  }
  .footer {
    width: 80%;
    height: 60px;
    margin: auto;
    border-radius: 53px;
    padding: 0px;
  }
}