@import '../../../assets/styles/scss/style.scss';
.login {
  width: 100vw;
  min-height: 100vh;
  background-color: $VeryLightGrey;
  &-container {
    min-height: 100vh;
    height: 100%;
    .image {
      display: inline-block;
      background-image: url('../../../assets/images/Getty.svg');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      border-radius: 0px 20px 20px 0px;
    }
    .content-login {
      height: 100%;
      width: 100%;
      padding: 0 25%;
      margin-bottom: 40px;
      float: right;
      position: relative;
      .header {
        width: 100%;
        height: 150px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 80px;
        .path {
          top: -220px;
          left: -25px;
          width: 300%;
          height: 300%;
          position: absolute;
          transform: rotate(90deg);
        }
        .logo {
          z-index: 1;
        }
      }
    }
    .main {
      .title-registration {
        margin: 0px;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.15px;
        color: $MainGrey
      }
      .title-step {
        margin: 0px;
        font-style: normal;
        font-weight: bold;
        line-height: 45px;
        font-size: 32px;
        letter-spacing: 0.15px;
        color: $DarkGrey;
        margin-bottom: 40px;
      }
      .title-welcome {
        font-style: normal;
        font-weight: bold;
        line-height: 45px;
        font-size: 32px;
        letter-spacing: 0.15px;
        color: $DarkGrey;
        text-align: center;
        margin-bottom: 10px;
        font-size: 22px;
        margin: 0px;
      }
      .description {
        font-family: 'Roboto Regular';
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        margin-bottom: 0px;
      }
      .align-end{
        text-align: end;
      }
      .text-field {
        width: 100%;
        margin-bottom: 25px;
        fieldset {
          background: $White !important;
        }
      }
      .text-password{
        font-family: 'Roboto Regular';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: $MainGrey;
        margin: 0px 5px 10px 5% !important;
      }
      .text-forgot-password {
        font-family: 'Roboto Regular';
        text-align: right;
        font-size: 14px;
        line-height: 24px;
        color: $MainGrey;
        &:hover {
          text-decoration: underline;
          color: $MainPink;
        }
      }
    }
    .text-account-verification {
      font-family: 'Roboto Regular';
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $DarkGrey;
      margin-bottom: 30px;

    }
    .text-field {
      width: 100%;
    }
    .button {
      width: 80%;
      margin: 30px 10% 0px 10%;
    }
  }
}

@media (max-width: $Mobile) {
  .registration {
    &-container {
      .content {
        padding: 0px 5%;
      }
    }
  }
}