@import '../../../../assets/styles/scss/style.scss';

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  background: $DarkGrey;
  opacity: 0.5;
  z-index: 100;
}
