.w-20p {
  width: 20% !important;
}

.w-30p {
  width: 30% !important;
}

.w-50p {
  width: 50% !important;
}

.w-80p {
  width: 80% !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-25p {
  margin-left: 25% !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-25p {
  margin-right: 25% !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pl-10p {
  padding-left: 10% !important;
}

.pr-10p {
  padding-right: 10% !important;
}

.float-none {
  float: none !important;
}

.deg-180 {
  transform: rotateY(180deg) !important;
}

.group-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}