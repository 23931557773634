@import '../../../assets/styles/scss/style.scss';

.landing {
  background-color: $White;
  height: 100%;
  .first-content {
    display: flex;
    background-image: url('../../../assets/images/Rectangle.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: bottom;
    >.left {
      display: inline-flex;
      width: 50%;
      justify-content: flex-end;
      align-items: flex-end;
      height: 850px;
      position: relative;
      >.image {
        margin-left: 100%;
        position: absolute;
        bottom: -15%;
        @media all and (max-width: $Mobile) {
          width: 100vw;
          height: auto;
        }
      }
    }
    >.right {
      @extend .left;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      position: unset;
      >.content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        >.title {
          font-family: 'Times New Roman', Times, serif;
          color: $White;
          font-weight: 700;
          font-size: 57px;
          text-align: center;
        }
        >.describe {
          font-family: Arial, Helvetica, sans-serif;
          color: $White;
          font-size: 28px;
          line-height: 34px;
          text-align: center;
        }
        >.button {
          height: 50px;
          margin-top: 45px;
          width: 180px;
          background-color: $DarkGrey;
          border-radius: 5px !important;
          .image {
            margin-right: 11px;
          };
          .texts {
            margin: 11px 0;
            color: $White;
          }
        }
      }
      >.footer {
        position: absolute;
        bottom: -2%;
        right: 14%;
        @media all and (max-width: $LargeDesktop) {
          bottom: -14%;
        }
        @media all and (max-width: $Desktop) {
          bottom: -20%;
        }
        >.image {
          width: 250px;
          height: 250px;
        }
      }
    }
    >.image {
      position: absolute;
      bottom: -2%;
      left: calc(50% - 97px);
      width: 97px;
    }
  }
  .second-content {
    background-color: $White;
    margin-top: 100px;
    height: 700px;
    max-height: 700px;
    position: relative;
    >.icon-green {
      position: absolute;
      left: 15%;
      bottom: 20%;  
    }
    >.icon-yellow {
      position: absolute;
      right: 15%;
      top: 0%;
    }
    >.texts {
      text-align: center;
      color: $DarkGrey;
      .describe {
        font-family: 'Asap';
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.381818px;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #000000;        
      }
      .title {
        font-family: 'Asap';
        font-size: 40px;
        font-weight: bold;
        line-height: 50px;
        letter-spacing: 0.514286px;
        text-transform: uppercase;
      }
    }
    >.main {
      width: 100%;
      display: flex;
      margin-top: 15px;
      >.right {
        width: 55%;
        >.slide {
          width: 50%;
        }
      }
      >.left {
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 0 0 5% 10%;
        .content {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          width: 60%;
          height: 100%;
          >.texts {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .title {
              font-family: 'Asap';
              font-style: normal;
              font-weight: bold;
              font-size: 32px;
              line-height: 37px;
              text-align: center;
              letter-spacing: 0.411429px;
              text-transform: uppercase;
              margin-top: 35px;
            }
            .describe {
              font-family: 'Asap';
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 28px;
              text-align: center;
              letter-spacing: 0.381818px;
              margin-top: 35px;
            }
          }
        }
        .three-dots {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 160px;
          height: 12px;
          margin-top: 60px;
          .dot {
            width: 8px;
            height: 8px;
            background: $LightBlue;
            margin: 5px;
            border-radius: 50%;
          }
          .active {
            width: 12px;
            height: 12px;
            transition: 0.2s all ease-in-out;
          }
        }
      }
    }
  }
  .third-content {
    @extend .first-content;
    background-image: url('../../../assets/images/BackGroundFooter.svg');
    background-position-y: top;
    position: relative;
    >.left {
      >.image {
        margin-left: 100%;
        position: absolute;
        bottom: 0%;
      }
    }
    .cream-ball {
      position: absolute;
      right: 15%;
      top: 20%;
    }
    .yellow-ball {
      position: absolute;
      right: 30%;
      top: 10%;
    }
    .pink-ball {
      position: absolute;
      top: 25%;
      right: 50%;
    }
    .blue-ball {
      position: absolute;
      bottom: 15%;
      left: 12%;
    }
    .lightPink-ball-left {
      position: absolute;
      top: 20%;
      left: 15%;
    }
    .lightPink-ball-right {
      position: absolute;
      bottom: 20%;
      right: 15%;
    }
  }
  >.footer {
    background: #000000;
    padding: 30px 200px 70px 200px;
    @media all and (max-width: $Mobile) {
      padding: 30px 50px 70px 50px;
    }
    >.top {
      >.group {
        color: $White;
        text-align: start;
        >.title {
          font-family: Arial, Helvetica, sans-serif;
          font-size: 20px;
          line-height: 24px;
          text-align: start;
          letter-spacing: 0.38px;
          color: $White;
          margin-bottom: 25px;
        }
        >.headline {
          font-family: Arial, Helvetica, sans-serif;
          font-size: 16px;
          line-height: 21px;
          text-align: start;
          letter-spacing: -0.32px;
          color: $White;
          margin-bottom: 10px;
        }
        >.input-ios {
          border: 1px solid #ffffff;
          height: 50px;
          width: 100%;
          display: flex;
          justify-content: center;
          border-radius: 30px;
          padding: 20px 0px 20px 25px;
          .MuiInput-underline:after {
            border-bottom: unset !important;
          }
        }
      }
    }
    >.bottom {
      margin-top: 50px;
      >.text {
        display: flex;
        align-items: flex-end;
        >.title {
          font-family: 'Times New Roman', Times, serif;
          font-style: normal;
          font-weight: bold;
          font-size: 46px;
          line-height: 46px;
          text-align: center;
          letter-spacing: 0.506px;
          color: $White;
          margin-right: 20px;
        }
        >.coppy-right {
          font-family: Arial, Helvetica, sans-serif;
          font-size: 16px;
          line-height: 25px;
          text-align: center;
          letter-spacing: -0.32px;
          color: $White;
        }
      }
    }
  }
}



@media all and (max-width: $LargeTablet) {
  .landing {
    .first-content {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      >.left {
        height: 850px;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        >.image {
          height: 100%;
          margin: 0;
          bottom: 0;
          z-index: 10;
          position: absolute;
        }
        >.button {
          height: 50px;
          margin-top: 45px;
          width: 180px;
          background-color: $DarkGrey;
          border-radius: 5px !important;
          position: absolute;
          bottom: 20px;
          .image {
            margin-right: 11px;
          };
          .texts {
            margin: 11px 0;
            color: $White;
          }
        }
      }
      >.right {
        height: auto;
        >.content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          >.title {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            >.image {
              width: 30%;
            }
          }
        }
        >.footer {
          position: absolute;
          bottom: -250%;
          right: -45%;
        }
      }
    }
    .second-content {
      height: auto;
      max-height: unset;
      >.main {
        margin-top: 0;
        flex-direction: column-reverse;
        >.right {
          width: 100%;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          >.slide {
            width: 80%;
          }
        }
        >.left {
          padding: 0;
          width: 100%;
          .content {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: start;
            width: 100%;
            padding: 0 5%;
            >.texts {
              .title {
                text-align: start;
                width: 100%;
              }
              .describe {
                text-align: start;
                margin-top: 15px;
              }
            }
            >.image {
              width: 25%;
              margin-right: 15px;
              margin-top: 37px;
            }
          }
          .three-dots {
            margin-top: 30px;
          }
        }
      }
    }
    .third-content {
      margin-top: 30px;
      flex-direction: column;
      >.right {
        width: 100%;
        margin-bottom: 80px;
      }
    }
    >.footer {
      >.top {
        >.group {
          margin-bottom: 50px;
          >.title {
            text-align: center;
            margin-bottom: 20px;
          }
          >.headline {
            margin-bottom: 10px;
            text-align: center;
          }
        }
      }
    }
  }
}

@media all and (max-width: $Tablet) {
  .landing {
    .first-content {
      >.right {
        >.footer {
          right: -45%;
          >.image {
            width: 180px;
            height: 180px;
          }
        }
      }
    }
  }
}
@media all and (max-width: $Mobile) {
  .landing {
    .first-content {
      >.left {
        height: 480px;
        width: 100%;
      }
      >.right {
        height: auto;
        >.footer {
          bottom: -150%;
          right: 0%;
          >.image {
            width: 150px;
            height: 150px;
          }
        }
      }
      >.image {
        bottom: 10%;
        left: calc(30% - 97px);
        width: 70px;
      }
    }
    >.second-content {
      >.icon-green {
        right: 0;
        left: auto;
        bottom: 22%;    
      }
      >.icon-yellow {
        display: none;
      }
      >.main {
        >.left {
          >.content {
            >.texts {
              >.title {
                font-size: 20px;
                line-height: 23px;            
              }
              >.describe {
                margin-top: 5px;
                font-size: 15px;
                line-height: 18px;
              }
            }
          }
        }
      }
    }
    >.third-content {
      >.right {
        >.content {
          >.title {
            font-size: 35px;
          }
          >.describe {
            text-align: center;
          }
        }
      }
    }
    >.footer {

    }
  }
}