@import '../../../assets/styles/scss/style.scss';

.user-management {
  padding: 53px 28px 0px 28px;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .title {
      font-family: "Carmen Sans";
      font-weight: 500;
      line-height: 50px;
      font-size: 22px;
      margin-bottom: 0px !important;
    }
  }
}