@import '../../../../assets/styles/scss/style.scss';
.item {
  margin: 0!important;
  padding: 20px auto !important;
  &-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: $MainGrey;
    &-name {
      font-weight: 500;
      font-size: 18px;
      color: $DarkGrey
    }
    &-notification {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: normal;
      color: $MainGrey;
      font-size: 14px;
    }
    &-logout {
      color: $DarkGrey !important;
      font-weight: normal !important;
    }
    &-close {
      visibility: hidden;
    }
    &-open {
      margin-left: 10px;
    }
  }
  &-icon {
    display: block;
    &-avatar {
      padding: 0px 1px;
      .have-notification {
        border: 2px solid $White;
        border-radius: 50%;
        box-shadow: 0px 0px 0px 2.5px $MainPink;
      }
    }
    &-close {
      width: 100%;
      min-width: 0;
    }
  }
  &-active {
    color: $MainPink !important;
  }
  &-logout {
    margin-top: auto !important;
  }
}

.arrow {
  transition: all 0.2s ease-in-out;  
}

.path {
  position: absolute;
  top: -18.5%;
  left: -60%;
  transform: rotate(30deg);
  transition: all 0.2s;
}

.contain-img {
  width: 100%;
  display: block;
  padding: 0 15px;
  &-img {
    height: 1.5rem;
  }
}

@media all and (max-width: $Desktop) {
  .path {
    top: -12%;
    left: -63%;
  }
}

@media all and (max-width: $LargeTablet) {
  .path {
    top: -16.5%;
    left: -58%;
  }
}
