@import './_variables.scss';
// custom css 
img {
  object-fit: contain;
  margin: 0px;
}

a {
  color: #3273dc;
  cursor: pointer;
  text-decoration: none;
}

.custom-css-outlined-input {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-size: 16px;
  line-height: 19px;
  color: $DarkGrey
}

.MuiTableCell-alignRight {
  text-align: right !important;
}

.MuiButton-outlinedSecondary {
  border-color: $MainPink !important;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.MuiMenu-paper {
  box-shadow: 0px 0px 8px 0px $HalfGrey !important;
}

.MuiInputBase-input {
  color: $MainGrey !important;
}

.MuiInputBase-input.Mui-disabled {
  cursor: not-allowed !important;
}

.MuiSelect-select.Mui-disabled {
  cursor: not-allowed !important;
}

.MuiOutlinedInput-input {
  z-index: 1;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: transparent;
}

.select:not(.is-multiple):not(.is-loading):hover::after {
  border-color: transparent;
}

.MuiButton-contained.Mui-disabled {
  background-color: $MainPink !important;
  cursor: not-allowed !important;
  opacity: 0.4;
}

.MuiSelect-icon {
  top: calc(50% - 5px) !important;
}

.MuiSvgIcon-root {
  z-index: 1;
}

.MuiSvgIcon-fontSizeSmall {
  font-size: 1rem !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-right: 125px !important;
}

span.MuiFormLabel-asterisk.MuiInputLabel-asterisk {
  color: $MainPink;
}

.MuiPaper-rounded {
  border-radius: 15px !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: $MainPink !important;
}

.button {
  width: 160px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 53px !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-transform: none !important;
}

.item-14 {
  width: 100%;
  margin-right: 16px;
}

.loading-modal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  background: $White;
  opacity: 1;
  transition: ease-in-out 0.5s all;
  z-index: 100;
}

.background-color {
  height: 40px;
  width: 40px;
  border-radius: 8px;
}

.badge-img:hover{
  cursor: pointer;
}

.content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

::marker {
  unicode-bidi: unset;
  font-variant-numeric: unset;
  text-transform: none;
}

.cursor-pointer{
  cursor: pointer;
}

.ant-modal-wrap {
  z-index: 10000;
}

.text-field {
  width: 100%;
}

.change-coins {
  width: 50%;
  display: inline-flex;
  justify-content: flex-start;
}