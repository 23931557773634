@import '../../../../assets/styles/scss/style.scss';

.custom-table {
  background-color: $White;
  margin-top: 14px;
  border-radius: 5px;
  .filter {
    width: 60%;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 28px;
    &-title {
      font-size: 16px;
      line-height: 19px;
      color: $MainGrey;
      font-weight: 500;
      margin-right: 28px;
    }
    &-select {
      width: 250px;
      margin-right: 28px;
    }
  }

  .table-container {
    .content {
      .header {
        .cell {
          color: $MainGrey !important;
        }
      }
    }
  }
  .pagination-container {
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
