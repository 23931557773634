@import '../../../../assets/styles/scss/style.scss';
@import '../UpdateTemplateModal/index.scss';

.update-review-modal{
  .header-tabs {
    .header {
      display: block;
      height: 180px;
      width: 500px;
      margin: 0px auto 10px auto;
      padding: 0px 8%;
      .cover-img {
        margin: 0px;
        position: absolute;
        width: 70%;
        left: 13%;
        top: 0px;
      }
      .container-avatar {
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }
      .text-fullname {
        font-size: 18px;
        line-height: 24px;          
        text-align: center;
        color: $DarkGrey
      }
      .container-select {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        .select {
          padding: 2px 10px;
          border-radius: 34px;
        }
        .button-approve {
          border: 2px solid $Red;
          border-radius: 34px;
          .text {
            margin: 0px 10px;
            text-transform: none;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.1px;
          }
        }
      }
    }
    .content {
      padding: 0px 8%;
      margin-bottom: 40px;
      .title {
        font-family: 'Carmen Sans';
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0.25px;
        font-size: 22px;
      }
      .row {
        flex-grow: 1;
      }
      .text-reason {
        width: 100%;
      }
      .text-field {
        width: 100%;
      }
    }
    .footer {
      background-color: $White;
      padding: 0px 8%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      .button {
        width: 100%;
      }
    }
  }
}