@import '../../../../assets/styles/scss/style.scss';

.empty-result {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.5rem;
  .error-icon {
    margin-right: 10px;
  }
  .text {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.25px;
    color: $DarkGrey;
  }
}