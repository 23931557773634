.justify-content-center {
  justify-content: center !important;
}

.justify-content-space-around {
  justify-content: space-around !important;
}

.none {
  display: none !important;
}

.hidden {
  visibility: hidden;
}

.position-relative {
  position: relative;
}

.background-green {
  background-color: $HalfGreen;
}

.background-light-grey-1 {
  background-color: $LightGrey1;
}

.z-index-1 {
  z-index: 1;
}

.background-loading {
  z-index: 10;
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  bottom: 0px;  
}