// Colors
$MainPink: #D75F8D;
$PastelPink: #E28BAE;
$Peach: #FFB7B7;
$Beige: #EFD4C1;
$Coral: #E1ABA8;
$CoolBlue: #9DB6DF;
$LightBlue: #A2CBE3;
$MainGreen: #01B41F;
$HalfGreen: #83C573;
$LightGreen: #C4E1C9;
$MainGrey: #747474;
$DarkGrey: #262626;
$HalfGrey: #B1B1B1;
$LightGrey1: #DBDBDB;
$LightGrey2: #E5E5E5;
$VeryLightGrey: #F7F7F7;
$White: #FFFFFF;
$Red: #EB5757;
$MainGreen3: #828282;
$MainGreen4: #E5E5EA;

// Font
$FontSize14: 14px;
$FontSize16: 16px;


// Breakpoints 
$LargeDesktop: 1350px !default;
$Desktop: 1024px !default;
$LargeTablet: 800px !default;
$Tablet: 600px !default;
$Mobile: 480px !default;
$SmallMobile: 320px !default;
