@import '../../../assets/styles/scss/style.scss';

body {
  background: $VeryLightGrey;
  width: 100vw;
  min-height: 100vh;
  overflow-y: auto;
}

.app-layout {
  &-loading {
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-color: $White;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  flex-grow: 1;
  height: auto;
  min-height: 100vh;
  z-index: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  width: 100%;
  background-color: $VeryLightGrey;
}

.MuiOutlinedInput-notchedOutline {
  border: transparent !important;
}