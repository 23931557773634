@import '../../../../assets/styles/scss/style.scss';

.custom-search {
  width: 100%;
  margin-bottom: 28px;
  .text-field {
    width: 92%;
    border-radius: 5px;
    fieldset {
      background: $White !important;
    }
    .icon-search {
      z-index: 1;
    }
  }

  &-logo {
    height: 60px;
    display: inline-flex;
    float: right;
    justify-content: center;
    padding: 5px;
    align-items: center;
    margin-right: 30px;
  }
}